.loading {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.55);
    position: absolute;
    z-index: 9999;
    flex-flow: column;
}