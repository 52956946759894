::-webkit-scrollbar {
    width: 8px;
  }
  
::-webkit-scrollbar-thumb {
-webkit-border-radius: 10px;
border-radius: 10px;
background: rgba(8, 60, 120, 1);
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

@media print {
  body {
    > div {
      width: 50%;
      float: left;
    }
    .remove-recibo {
      display: none;
    }
  }
  
}